import { useWindowSize } from "@helpers/Hooks";
import { Pagination } from "antd";
import classNames from "classnames";
import React from "react";
import { TypographyV2 } from "..";
import * as styles from "./Paginator.module.scss";

type Props = {
	current: number;
	onChange?: (page: number, pageSize: number) => void;
	pageSize: number;
	total: number;
};

const Paginator = ({ pageSize, total, current, onChange }: Props) => {
	const { isDesktop } = useWindowSize();
	const totalPages = Math.ceil(total / pageSize);

	const previousButton = (
		<div
			className={classNames(styles.arrowButton, styles.left, {
				[styles.disabled]: current === 1,
			})}
			onClick={() => {}}
		>
			<svg
				style={{
					minWidth: 20,
					minHeight: 20,
					color:
						current === 1 ? "var(--gray-400)" : "var(--gray-700)",
				}}
				width="20"
				height="21"
				viewBox="0 0 20 21"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15.8334 10.3359H4.16675M4.16675 10.3359L10.0001 16.1692M4.16675 10.3359L10.0001 4.50256"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
			{isDesktop && (
				<TypographyV2
					variant="BODY_TEXT_SMALL"
					weight="MEDIUM"
					color={current === 1 ? "gray-400" : "gray-700"}
				>
					Previous
				</TypographyV2>
			)}
		</div>
	);

	const nextButton = (
		<div
			className={classNames(styles.arrowButton, styles.right, {
				[styles.disabled]: current === totalPages,
			})}
			onClick={() => {}}
		>
			{isDesktop && (
				<TypographyV2
					variant="BODY_TEXT_SMALL"
					weight="MEDIUM"
					color={current === totalPages ? "gray-400" : "gray-700"}
				>
					Next
				</TypographyV2>
			)}
			<svg
				style={{
					minWidth: 20,
					minHeight: 20,
					color:
						current === totalPages
							? "var(--gray-400)"
							: "var(--gray-700)",
				}}
				width="20"
				height="21"
				viewBox="0 0 20 21"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4.16675 10.3359H15.8334M15.8334 10.3359L10.0001 4.50256M15.8334 10.3359L10.0001 16.1692"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);

	return (
		<div className={styles.paginator}>
			<Pagination
				onChange={onChange}
				current={current}
				prevIcon={previousButton}
				nextIcon={nextButton}
				pageSize={pageSize}
				total={total}
				showSizeChanger={false}
			/>
		</div>
	);
};

export default Paginator;
